import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import CmsAction from 'pages/cms/actions/CmsAction'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Button, Grid2, Icon, TextField } from '@mui/material'
import SimpleMultiAutocomplete from 'components/SimpleMultiAutocomplete'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'pages/online/components/styled/dialog'
import { mainWhite } from 'components/styled/Theme'
import SimpleDatePicker from 'components/SimpleDatePicker'
import { EVENT_TYPES } from '../constants/CmsConstants'
import { getI18nOrLabel } from 'utils/StringUtil'
import HomeAction from 'pages/home/actions/HomeAction'
import { getStationTypeCodeFromType } from 'utils/StationUtils'
import ToastrAction from 'toastr/actions/ToastrAction'

const AddEventDialog = ({
    stationType,
    stationId,
    isOpen = false,
    closeDialog = () => {},
}) => {
    const [event, setEvent] = useState({})

    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            setEvent({})
        }
    }, [])

    const addEvent = () => {
        if (event.eventType && event.startDate && event.endDate && event.comment) {
            dispatch(CmsAction.addEvent({
                stationType,
                stationId,
                event: {
                    code: stationId,
                    graph: '0',
                    bankExport: '0',
                    date: event.startDate,
                    ...event,
                },
            })).then(() => {
                setEvent({})
                dispatch(HomeAction.fetchAllLinkedStationsEvents({ ids: [stationId], stationType: getStationTypeCodeFromType(stationType) }))
                closeDialog()
            })
        } else {
            dispatch(ToastrAction.warning(i18n.fillAllFields))
        }
    }

    const eventsOpt = useMemo(() => EVENT_TYPES.map(e => ({ ...e, name: getI18nOrLabel(e.label) })), [])

    return (
        <DialogMUI
            open={isOpen}
            maxWidth='md'
            PaperProps={{
                sx: {
                    minHeight: 'auto',
                    maxHeight: 'auto',
                },
            }}
        >
            <DialogTitleMUI>
                <Grid2 container justifyContent='space-between' alignItems='center'>
                    <Grid2>
                        <h3 style={{ margin: '0px', fontWeight: '600', fontSize: '20px' }}>{i18n.addOneEvent}</h3>
                    </Grid2>
                    <Grid2>
                        <Icon
                            sx={{ color: mainWhite, cursor: 'pointer' }}
                            size='small'
                            icon='close'
                            onClick={() => {
                                setEvent({})
                                closeDialog()
                            }}
                        >
                            close
                        </Icon>
                    </Grid2>
                </Grid2>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid2 container alignItems='center' spacing={1}>
                    <Grid2 size={6}>
                        <SimpleMultiAutocomplete
                            value={event.eventType}
                            onChange={eventType => setEvent(prev => ({ ...prev, eventType }))}
                            options={eventsOpt}
                            keyValue='code'
                            keyLabel='name'
                            label={i18n.category}
                            disablePortal={false}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <SimpleDatePicker
                            label={i18n.startDate}
                            fullWidth
                            maxDate={event.endDate}
                            value={event.startDate}
                            onChange={startDate => setEvent(prev => ({ ...prev, startDate }))}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <SimpleDatePicker
                            label={i18n.endDate}
                            fullWidth
                            minDate={event.startDate}
                            value={event.endDate}
                            onChange={endDate => setEvent(prev => ({ ...prev, endDate }))}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={12} sx={{ marginTop: '1rem' }}>
                        <TextField
                            color='primary'
                            label={i18n.comment}
                            type='text'
                            value={event.comment}
                            onChange={(e) => setEvent(prev => ({ ...prev, comment: e.target.value }))}
                            variant='outlined'
                            multiline
                            sx={{ width: '100%', lineHeight: '15px', margin: '5px 0' }}
                            rows={5}
                            required
                        />
                    </Grid2>
                </Grid2>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid2 container justifyContent='flex-end' alignItems='center'>
                    <Grid2 size='auto'>
                        <Button variant='contained' color='primary' fullWidth onClick={addEvent}>{i18n.add}</Button>
                    </Grid2>
                </Grid2>
            </DialogActionsMUI>
        </DialogMUI>
    )
}


AddEventDialog.propTypes = {
    stationType: PropTypes.string,
    stationId: PropTypes.number,
    isOpen: PropTypes.bool,
    closeDialog: PropTypes.func,
}

export default AddEventDialog