import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { TextField } from '@mui/material'
import moment from 'moment'

const SimpleDatePicker = ({
    label = '',
    value,
    onChange = () => {},
    fullWidth = false,
    maxDate,
    minDate,
    sx = {},
    disabled,
    obligatory,
}) => {
    const inputRef = useRef()

    useEffect(() => {
        if (!value) {
            onChange(moment().valueOf())
        }
    }, [])

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
                label={obligatory ? `${label} *` : label}
                inputFormat='DD/MM/YYYY'
                onChange={newValue => onChange(moment(newValue).valueOf())}
                inputRef={inputRef}
                value={value ? moment(value) : undefined}
                minDate={minDate ? moment(minDate) : undefined}
                maxDate={maxDate ? moment(maxDate) : undefined}
                disabled={disabled}
                renderInput={({ InputProps, InputLabelProps, ...params }) => (
                    <TextField
                        {...params}
                        variant='outlined'
                        color='primary'
                        fullWidth={fullWidth}
                        InputLabelProps={{
                            ...InputLabelProps,
                            sx: {
                                top: inputRef?.current?.value?.length ? '0.35rem' : 0,
                                '&.Mui-focused': {
                                    top: '0.35rem',
                                },
                            },
                        }}
                        InputProps={{
                            ...InputProps,
                            sx: {
                                margin: '5px 0',
                                width: '100% !important',
                                zIndex: '0',
                                '& input': {
                                    padding: '0.75rem',
                                },
                                ...sx,
                            },
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    )
}

SimpleDatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
    maxDate: PropTypes.number,
    minDate: PropTypes.number,
    sx: PropTypes.shape({}),
    disabled: PropTypes.bool,
    obligatory: PropTypes.bool,
}

export default SimpleDatePicker