import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { getTextColorByBackground } from 'utils/ColorUtil'
import { Collapse, Divider, Grid2, Icon, Popover, Tooltip, Typography } from '@mui/material'
import { disabledColor, LARGE_RADIUS, mainWhite, MEDIUM_RADIUS } from 'components/styled/Theme'
import { HISTO, J30, J365 } from './ChartFollowContants'
import HomeAction from 'pages/home/actions/HomeAction'
import { CHART_SELECTED_TIME } from 'pages/home/constants/HomeConstants'
import { useDispatch } from 'react-redux'
import { DEFAULT_DISPLAY_COTE, DEFAULT_DISPLAY_MODES, DISPLAY_COTE, FOLLOW_STAT_MODES, MEASURE_COTE, PIEZO_TAB_DISPLAY_MODES, STATISTICS } from './ChartConstant'
import SimpleCheckbox from '../forms/SimpleCheckbox'
import { getLocalStorageJson, setLocalStorageJson } from 'utils/LocalStorageUtils'
import { ButtonMUI } from 'components/styled/Buttons'
import { OBSERVATORY_STATION_TYPE_NAME, STATION_TYPE_NAME } from 'pages/home/constants/StationConstants'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../styled/dialog'
import { compact, findIndex, groupBy, keys, maxBy, orderBy } from 'lodash'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import { mainBlue } from 'components/styled/Theme'
import DtoMeasureStats from 'pages/online/follows/dto/DtoMeasureStats'
import SortIcon from '@mui/icons-material/Sort'
import { SmallStyledFieldSet, StyledLegend } from 'components/StyledElements'
import { getI18nOrLabel } from 'utils/StringUtil'
import { getSubstractTime } from './EChartUtils'
import moment from 'moment'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'

const CHRONIC_TYPE = -1
const MAX_POPOVER_HEIGHT = 400

const roundDivStyle = {
    width: '1rem',
    height: '1rem',
    borderRadius: '50rem',
}

const TooManyDataDialog = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
}) => (
    <DialogMUI open={isOpen} PaperProps={{ sx: { minHeight: 'auto', maxHeight: 'auto' } }}>
        <DialogTitleMUI sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid2>
                <h3 style={{ margin: 0, fontWeight: '600', fontSize: '20px' }}>{i18n.payAttention}</h3>
            </Grid2>
            <Grid2>
                <Icon
                    sx={{ color: mainWhite, cursor: 'pointer' }}
                    size='small'
                    icon='close'
                    onClick={onClose}
                >
                        close
                </Icon>
            </Grid2>
        </DialogTitleMUI>
        <DialogContentMUI>
            <Grid2 container>
                <Grid2 size={12} sx={{ padding: '1rem 0' }}>
                    {i18n.tooManyDataConfirmationMessage}
                </Grid2>
            </Grid2>
        </DialogContentMUI>
        <DialogActionsMUI>
            <Grid2 container justifyContent='flex-end' alignItems='center'>
                <Grid2>
                    <ButtonMUI
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            onValidate()
                            onClose()
                        }}
                    >
                        {i18n.validate}
                    </ButtonMUI>
                </Grid2>
            </Grid2>
        </DialogActionsMUI>
    </DialogMUI>
)

TooManyDataDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
}

const ChartTabs = ({
    time = 30,
    onChangeTime = () => {},

    statistics = [],
    stationType,

    setDisplayCote = () => {},
    setDiplayModes = () => {},
    setStatModes = () => {},

    inprogress = false,

    dataTypes = [],
    setDataTypes = () => {},
}) => {
    const stats = compact([{
        id: STATISTICS.ENVELOPPE,
        label: i18n.enveloppeMinMax,
        dataTypes: [],
        open: false,
        display: false,
    }, stationType !== STATION_TYPE_NAME.pluviometry && {
        id: STATISTICS.ENVELOPPE_AVERAGE,
        label: i18n.averageStatistics,
        dataTypes: [],
        open: false,
        display: false,
    }])

    const statModesKey = `${stationType}_${FOLLOW_STAT_MODES}`

    const defaultDisplayCote = useMemo(() => getLocalStorageJson(DISPLAY_COTE) || DEFAULT_DISPLAY_COTE, [])
    const defaultDisplayMode = useMemo(() => getLocalStorageJson(PIEZO_TAB_DISPLAY_MODES) || DEFAULT_DISPLAY_MODES, [])
    const defaultStatModes = useMemo(() => {
        const storageStatModes = getLocalStorageJson(statModesKey)
        return storageStatModes?.length ? storageStatModes : stats
    }, [])

    const [settingsEl, setSettingsEl] = useState(undefined)
    const [dataTypeEl, setDataTypeEl] = useState(undefined)
    const [enveloppeEl, setEnveloppeEl] = useState(undefined)
    const [tempDisplayCote, setTempDisplayCote] = useState(defaultDisplayCote)
    const [tempDisplayModes, setTempDiplayModes] = useState(defaultDisplayMode)
    const [tempStatModes, setTempStatModes] = useState(defaultStatModes)

    const [tempDataTypes, setTempDataTypes] = useState(dataTypes)

    const [isOpenDisplayMode, setIsOpenDisplayMode] = useState(false)
    const [isOpenHisto, setIsOpenHisto] = useState(false)

    const [openReferenceLevel, setOpenReferenceLevel] = useState(true)
    const [openChoiceCurves, setOpenChoiceCurves] = useState(true)

    const withStats = useApplicationSetting('withStats', setting => setting === 'true' && !!dataTypes.length)
    const chartTabs = useApplicationSetting(`${stationType}Horizons`, setting => setting?.length ? setting.split(',').map(v => v !== HISTO ? parseInt(v) : v) : [J30, J365, HISTO])
    const limitHisto = useApplicationSetting(`${stationType}LimitHisto`, setting => setting?.length ? parseInt(setting) : undefined)

    const getBackgroundColor = timeScale => time === timeScale ? mainBlue : mainWhite

    const dispatch = useDispatch()

    const onChange = (newTime) => {
        const minDate = newTime !== HISTO ? getSubstractTime(newTime) : limitHisto ? getSubstractTime(limitHisto) : undefined
        const maxDate = (newTime === HISTO && !limitHisto) ? moment().valueOf() : undefined
        onChangeTime({ minDate, maxDate, time: newTime })
        dispatch(HomeAction.updateSetting({ settingName: CHART_SELECTED_TIME, value: `${newTime}` }))
    }

    const onChangeDisplayCote = (changes) => {
        setLocalStorageJson(DISPLAY_COTE, changes)
        setTempDisplayCote(changes)
    }

    const onChangeDisplayMode = (changes) => {
        const newDisplay = { ...tempDisplayModes, ...changes }
        if (!newDisplay.brute && !newDisplay.min && !newDisplay.max && !newDisplay.average) {
            newDisplay.auto = true
        } else {
            newDisplay.auto = false
        }
        setLocalStorageJson(PIEZO_TAB_DISPLAY_MODES, newDisplay)
        setTempDiplayModes(newDisplay)
    }

    const onChangeStatModes = (changes) => {
        setLocalStorageJson(statModesKey, changes)
        setTempStatModes(changes)
    }

    const onValidateDisplayMode = () => {
        setSettingsEl(undefined)
        setDisplayCote(tempDisplayCote)
        setDiplayModes(tempDisplayModes)
    }

    const checkConfirmation = (toCheck, setIsOpen = () => {}, cb = () => {}) => {
        if ([OBSERVATORY_STATION_TYPE_NAME.catchment, STATION_TYPE_NAME.piezometry].includes(stationType) && statistics.length) {
            const statBrute = statistics.find(stat => stat.typeId === CHRONIC_TYPE)
            if (toCheck && statBrute && statBrute.countTotal > 10000) {
                setIsOpen(true)
            } else {
                cb()
            }
        } else if ([STATION_TYPE_NAME.hydrometry, STATION_TYPE_NAME.pluviometry].includes(stationType) && statistics.length) {
            if (maxBy(statistics, c => c.countTotal).countTotal > 10000) {
                setIsOpen(true)
            } else {
                cb()
            }
        } else {
            cb()
        }
    }

    const isPiezo = [OBSERVATORY_STATION_TYPE_NAME.catchment, STATION_TYPE_NAME.piezometry].includes(stationType)
    const groupedDataTypes = groupBy(dataTypes, 'type')

    const defaultWidth = 90 * chartTabs.length

    const nbPopovers = [isPiezo, (dataTypes.length && stationType === STATION_TYPE_NAME.productionUnit), withStats].filter(f => !!f).length
    const width = defaultWidth + (36 * nbPopovers)

    const updateStatModes = (statId, newObj) => {
        const objIndex = findIndex(tempStatModes, tsm => tsm.id === statId)
        onChangeStatModes([...tempStatModes.slice(0, objIndex), newObj, ...tempStatModes.slice(objIndex + 1)])
    }

    return (
        <Grid2 size={12}>
            <Grid2
                container
                sx={{
                    width,
                    margin: '10px',
                    boxShadow: '0px 0px 16px 0px rgb(0 0 0 / 12%)',
                    textAlign: 'center',
                    borderRadius: '24px',
                    overflow: 'hidden',
                    padding: '0.35rem',
                    backgroundColor: !inprogress ? mainWhite : disabledColor,
                }}
                alignItems='center'
                justifyContent='space-between'
            >
                <Grid2
                    container size={12 - nbPopovers}
                    sx={{
                        '& div': {
                            lineHeight: '1.1',
                        },
                    }}
                >
                    {chartTabs.map(ct => (
                        <Grid2
                            key={`${ct}_J`}
                            size={12 / chartTabs.length}
                            className='clickable'
                            onClick={() => !inprogress && onChange(ct)}
                            sx={{
                                padding: '0.35rem 0',
                                transition: 'all 0.3s ease-in-out',
                                backgroundColor: inprogress ? disabledColor : getBackgroundColor(ct),
                                color: getTextColorByBackground(getBackgroundColor(ct)),
                                borderRadius: LARGE_RADIUS,
                            }}
                        >
                            {ct === HISTO ? HISTO : `${ct}J`}
                        </Grid2>
                    ))}
                </Grid2>
                {!!isPiezo && (
                    <Grid2
                        sx={{
                            width: '36px',
                            padding: '0.25rem 0',
                            transition: 'all 0.3s ease-in-out',
                            backgroundColor: inprogress ? disabledColor : mainBlue,
                            color: mainBlue,
                            borderRadius: '50rem',
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                backgroundColor: inprogress ? disabledColor : mainBlue,
                            },
                        }}
                        onClick={event => !inprogress && setSettingsEl(event.currentTarget)}
                    >
                        <TroubleshootIcon sx={{ color: mainWhite }} />
                    </Grid2>
                )}
                {!!(dataTypes.length && stationType === STATION_TYPE_NAME.productionUnit) && (
                    <Grid2
                        sx={{
                            width: '36px',
                            padding: '0.25rem 0',
                            transition: 'all 0.3s ease-in-out',
                            backgroundColor: inprogress ? disabledColor : mainBlue,
                            color: mainBlue,
                            borderRadius: '50rem',
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                backgroundColor: inprogress ? disabledColor : mainBlue,
                            },
                        }}
                        onClick={event => !inprogress && setDataTypeEl(event.currentTarget)}
                    >
                        <SortIcon sx={{ color: mainWhite }} />
                    </Grid2>
                )}
                {!!withStats && (
                    <Grid2
                        sx={{
                            width: '36px',
                            padding: '0.25rem 0',
                            transition: 'all 0.3s ease-in-out',
                            backgroundColor: inprogress ? disabledColor : mainBlue,
                            color: mainBlue,
                            borderRadius: '50rem',
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'scale(1.1)',
                                backgroundColor: inprogress ? disabledColor : mainBlue,
                            },
                        }}
                        onClick={event => !inprogress && setEnveloppeEl(event.currentTarget)}
                    >
                        <QueryStatsIcon sx={{ color: mainWhite }} />
                    </Grid2>
                )}
            </Grid2>
            {!!(!inprogress && isPiezo) && (
                <>
                    <Popover
                        open={!!settingsEl}
                        anchorEl={settingsEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        sx={{ borderRadius: MEDIUM_RADIUS, maxHeight: MAX_POPOVER_HEIGHT }}
                        onClose={() => setSettingsEl(undefined)}
                    >
                        <Grid2 container sx={{ width: '300px', padding: '1rem', border: `solid 2px ${mainBlue}` }} rowSpacing={2}>
                            <Collapse in={openReferenceLevel} collapsedSize={20}>
                                <Grid2
                                    container
                                    size={12}
                                    onClick={() => setOpenReferenceLevel(prev => !prev)}
                                    alignItems='center'
                                    justifyContent='space-between'
                                    sx={{ fontWeight: '600', cursor: 'pointer' }}
                                >
                                    <Grid2>{i18n.referenceLevel}</Grid2>
                                    <Grid2>{!openReferenceLevel ? '▼' : '▲'}</Grid2>
                                </Grid2>
                                <Grid2 container size={12} sx={{ paddingTop: '0.5rem' }}>
                                    <Grid2 size={12}>
                                        <SimpleCheckbox
                                            label={i18n.depthLastMarker}
                                            labelStyle={{ fontWeight: 'normal' }}
                                            checked={tempDisplayCote === MEASURE_COTE.DEPTH}
                                            onToggle={() => onChangeDisplayCote(MEASURE_COTE.DEPTH)}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleCheckbox
                                            label='NGF'
                                            labelStyle={{ fontWeight: 'normal' }}
                                            checked={tempDisplayCote === MEASURE_COTE.NGF}
                                            onToggle={() => onChangeDisplayCote(MEASURE_COTE.NGF)}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleCheckbox
                                            label={i18n.ground}
                                            labelStyle={{ fontWeight: 'normal' }}
                                            checked={tempDisplayCote === MEASURE_COTE.GROUND}
                                            onToggle={() => onChangeDisplayCote(MEASURE_COTE.GROUND)}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleCheckbox
                                            label={i18n.benchmarkHistory}
                                            labelStyle={{ fontWeight: 'normal' }}
                                            checked={tempDisplayCote === MEASURE_COTE.LANDMARK}
                                            onToggle={() => onChangeDisplayCote(MEASURE_COTE.LANDMARK)}
                                        />
                                    </Grid2>
                                </Grid2>
                            </Collapse>
                            <Collapse in={openChoiceCurves} collapsedSize={20}>
                                <Grid2
                                    container
                                    size={12}
                                    onClick={() => setOpenChoiceCurves(prev => !prev)}
                                    alignItems='center'
                                    justifyContent='space-between'
                                    sx={{ fontWeight: '600', cursor: 'pointer' }}
                                >
                                    <Grid2>{i18n.choiceCurves}</Grid2>
                                    <Grid2>{!openChoiceCurves ? '▼' : '▲'}</Grid2>
                                </Grid2>
                                <Grid2 container size={12} sx={{ paddingTop: '0.5rem' }}>
                                    <Grid2 size={12}>
                                        <SimpleCheckbox
                                            label='Auto'
                                            labelStyle={{ fontWeight: 'normal' }}
                                            beforeLabel={(
                                                <div style={{ ...roundDivStyle, backgroundColor: 'black' }} />
                                            )}
                                            afterLabel={(
                                                <Tooltip
                                                    placement='top'
                                                    arrow
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    componentsProps={{
                                                        tooltip: {
                                                            sx: {
                                                                maxWidth: 'fit-content',
                                                                width: 'fit-content',
                                                                fontSize: '14px',
                                                            },
                                                        },
                                                    }}
                                                    title={i18n.descriptionCumulAuto}
                                                >
                                                    <Icon sx={{ fontSize: '18px', color: 'grey' }}>info</Icon>
                                                </Tooltip>
                                            )}
                                            checked={tempDisplayModes.auto}
                                            onToggle={() => onChangeDisplayMode(DEFAULT_DISPLAY_MODES)}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleCheckbox
                                            label={i18n.gross}
                                            labelStyle={{ fontWeight: 'normal' }}
                                            beforeLabel={(
                                                <div style={{ ...roundDivStyle, backgroundColor: 'black' }} />
                                            )}
                                            afterLabel={(
                                                <Icon sx={{ fontSize: '18px', color: 'orange' }}>warning</Icon>
                                            )}
                                            checked={tempDisplayModes.brute}
                                            onToggle={event => onChangeDisplayMode({ brute: event.target.checked })}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleCheckbox
                                            label={i18n.min}
                                            labelStyle={{ fontWeight: 'normal' }}
                                            beforeLabel={(
                                                <div style={{ ...roundDivStyle, backgroundColor: 'red' }} />
                                            )}
                                            checked={tempDisplayModes.min}
                                            onToggle={event => onChangeDisplayMode({ min: event.target.checked })}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleCheckbox
                                            label={i18n.max}
                                            labelStyle={{ fontWeight: 'normal' }}
                                            beforeLabel={(
                                                <div style={{ ...roundDivStyle, backgroundColor: 'blue' }} />
                                            )}
                                            checked={tempDisplayModes.max}
                                            onToggle={event => onChangeDisplayMode({ max: event.target.checked })}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleCheckbox
                                            label={i18n.average}
                                            labelStyle={{ fontWeight: 'normal' }}
                                            beforeLabel={(
                                                <div style={{ ...roundDivStyle, backgroundColor: 'green' }} />
                                            )}
                                            checked={tempDisplayModes.average}
                                            onToggle={event => onChangeDisplayMode({ average: event.target.checked })}
                                        />
                                    </Grid2>
                                </Grid2>
                            </Collapse>
                            <Grid2 size={12}>
                                <ButtonMUI
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    onClick={() => checkConfirmation(tempDisplayModes.brute, setIsOpenDisplayMode, onValidateDisplayMode)}
                                >
                                    {i18n.toLoad}
                                </ButtonMUI>
                            </Grid2>
                        </Grid2>
                    </Popover>
                    <TooManyDataDialog
                        isOpen={isOpenDisplayMode}
                        onClose={() => setIsOpenDisplayMode(false)}
                        onValidate={onValidateDisplayMode}
                    />
                </>
            )}
            {!!((dataTypes.length && stationType === STATION_TYPE_NAME.productionUnit) && !inprogress) && (
                <Popover
                    open={!!dataTypeEl}
                    anchorEl={dataTypeEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{ borderRadius: MEDIUM_RADIUS, maxHeight: MAX_POPOVER_HEIGHT }}
                    onClose={() => setDataTypeEl(undefined)}
                >
                    <Grid2 container sx={{ width: '300px', padding: '1rem', border: `solid 2px ${mainBlue}` }} rowSpacing={2}>
                        {keys(groupedDataTypes).map(key => (
                            <Grid2 size={12} key={key}>
                                <SmallStyledFieldSet style={{ padding: '1rem' }}>
                                    <StyledLegend>{getI18nOrLabel(key)}</StyledLegend>
                                    <Grid2 container spacing={1}>
                                        {orderBy(groupedDataTypes[key], 'label').map(dt => (
                                            <Grid2 size={12} key={dt}>
                                                <SimpleCheckbox
                                                    label={dt.label}
                                                    labelStyle={{ fontWeight: 'normal' }}
                                                    checked={tempDataTypes.some(tdt => tdt.typeId === dt.typeId)}
                                                    onToggle={() => tempDataTypes.some(tdt => tdt.typeId === dt.typeId) ? setTempDataTypes(prev => prev.filter(p => p.typeId !== dt.typeId)) : setTempDataTypes(prev => [...prev, dt])}
                                                />
                                            </Grid2>
                                        ))}
                                    </Grid2>
                                </SmallStyledFieldSet>
                            </Grid2>
                        ))}
                        <Grid2 size={12} sx={{ paddingTop: '1rem' }}>
                            <ButtonMUI
                                variant='contained'
                                color='primary'
                                fullWidth
                                onClick={() => {
                                    setDataTypeEl(undefined)
                                    setDataTypes(tempDataTypes)
                                }}
                            >
                                {i18n.toLoad}
                            </ButtonMUI>
                        </Grid2>
                    </Grid2>
                </Popover>
            )}
            {!!(withStats && !inprogress) && (
                <Popover
                    open={!!enveloppeEl}
                    anchorEl={enveloppeEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{ borderRadius: MEDIUM_RADIUS, maxHeight: MAX_POPOVER_HEIGHT }}
                    onClose={() => setEnveloppeEl(undefined)}
                >
                    <Grid2 container sx={{ width: '300px', padding: '1rem', border: `solid 2px ${mainBlue}` }} rowSpacing={2}>
                        <Grid2 size={12}>
                            <Typography component='span' fontWeight={600}>{i18n.statistics}</Typography>
                        </Grid2>
                        {tempStatModes.map((st, index) => (
                            <Grid2 container key={`${st.label}`} size={12}>
                                {index !== 0 && (
                                    <Grid2 size={12}>
                                        <Divider />
                                    </Grid2>
                                )}
                                <Grid2 size={12}>
                                    <Collapse in={st.open} collapsedSize={30}>
                                        <Grid2
                                            container
                                            size={12}
                                            onClick={() => updateStatModes(st.id, { ...st, open: !st.open })}
                                            alignItems='center'
                                            justifyContent='space-between'
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            <Grid2>
                                                <SimpleCheckbox
                                                    label={st.label}
                                                    labelStyle={{ fontWeight: '600' }}
                                                    checked={st.display}
                                                    onToggle={() => updateStatModes(st.id, { ...st, display: !st.display })}
                                                />
                                            </Grid2>
                                            <Grid2>{!st.open ? '▼' : '▲'}</Grid2>
                                        </Grid2>
                                        <Grid2 container size={12} sx={{ padding: '0.25rem 0 0.25rem 1rem' }} rowSpacing={1}>
                                            {dataTypes.map(dt => {
                                                const selected = st.dataTypes.includes(dt.typeId)
                                                return (
                                                    <Grid2 size={12} key={dt.label}>
                                                        <SimpleCheckbox
                                                            label={dt.label}
                                                            labelStyle={{ fontWeight: 'normal' }}
                                                            checked={selected}
                                                            onToggle={() => {
                                                                if (selected) {
                                                                    updateStatModes(st.id, { ...st, dataTypes: st.dataTypes.filter(d => d !== dt.typeId) })
                                                                } else {
                                                                    updateStatModes(st.id, { ...st, dataTypes: [ ...st.dataTypes, dt.typeId ] })
                                                                }
                                                            }}
                                                        />
                                                    </Grid2>
                                                )
                                            })}
                                        </Grid2>
                                    </Collapse>
                                </Grid2>
                            </Grid2>
                        ))}
                        <Grid2 size={12} sx={{ paddingTop: '1rem' }}>
                            <ButtonMUI
                                variant='contained'
                                color='primary'
                                fullWidth
                                onClick={() => {
                                    setEnveloppeEl(undefined)
                                    setStatModes(tempStatModes)
                                }}
                            >
                                {i18n.toLoad}
                            </ButtonMUI>
                        </Grid2>
                    </Grid2>
                </Popover>
            )}
            <TooManyDataDialog
                isOpen={isOpenHisto}
                onClose={() => setIsOpenHisto(false)}
                onValidate={() => onChange(HISTO)}
            />
        </Grid2>
    )
}

ChartTabs.propTypes = {
    time: PropTypes.number,
    onChangeTime: PropTypes.func,
    statistics: PropTypes.arrayOf(PropTypes.shape({})),
    stationType: PropTypes.string,
    setDisplayCote: PropTypes.func,
    setDiplayModes: PropTypes.func,
    setStatModes: PropTypes.func,
    inprogress: PropTypes.bool,
    dataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMeasureStats)),
    setDataTypes: PropTypes.func,
}

export default ChartTabs