import { styled } from '@mui/styles'
import { mainBlack, SMALL_RADIUS } from './styled/Theme'

const StyledFieldSet = styled('fieldset')({
    borderRadius: SMALL_RADIUS,
    border: 'solid 1px grey',
    padding: '5px 8px',
    backgroundColor: 'white',
    margin: '10px 0 0 0',
})

const SmallStyledFieldSet = styled('fieldset')({
    borderRadius: SMALL_RADIUS,
    border: 'solid 1px grey',
    padding: '0.5rem',
    backgroundColor: 'white',
    margin: 0,
})

const StyledLegend = styled('legend')({
    backgroundColor: 'white',
    border: 'solid grey 1px',
    borderRadius: SMALL_RADIUS,
    padding: '0px 10px',
    color: mainBlack,
    fontWeight: 'bold',
})

const StyledSpan = styled('span')({
    lineheight: '12px',
    fontSize: '16px',
})

export { StyledFieldSet, SmallStyledFieldSet, StyledLegend, StyledSpan }