/* eslint-disable react/react-in-jsx-scope */
import { createTheme } from '@mui/material/styles'
import { frFR } from '@mui/material/locale'

const mainWhite = '#fafafa'
const mainBlack = '#161832'

const mainBlue = '#3f51b5'
const mainGreen = '#79DA4C'
const mainOrange = '#F6B427'
const mainGrey = '#ECEFF1'
const mainRed = '#f32525'

const secondaryBlue = '#53A1FF'
const secondaryGrey = '#d5d8de'

const strongGrey = '#5c5c5c'
const mediumGrey = '#75838F'
const lightGrey = 'rgb(208, 208, 208)'
const disabledColor = '#949494'
const selectedColor = '#e0e0e0'
const chartGrey = 'rgb(163, 163, 163)'
const bgGrey = '#f0f0f0'

const managerBlue = '#3f51b5'
const lightBlue = '#9BC2E6'
const textBlue = '#01136b'

const badWarning = '#f6b427'

const defaultPiezoColor = '#153683'
const defaultHydroColor = '#1CBDC8'
const defaultPluvioColor = '#873A96'
const defaultQualitoColor = '#F68F1F'
const defaultMonitoringColor = '#0087FF'

export const ellipsisText = {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
}

const THRESHOLD_COLORS_CODES = {
    BLACK: '#000000',
    BLUE: '#0000FF',
    GREEN: '#008000',
    CYAN: '#00FFFF',
    RED: '#FF0000',
    DARK_MAGENTA: '#8B008B',
    YELLOW: '#FFFF00',
    WHITE: '#FFFFFF',
    GREY: '#808080',
    LIGHT_BLUE: '#ADD8E6',
    LIGHT_GREEN: '#90EE90',
    LIGHT_CYAN: '#E0FFFF',
    INDIANRED: '#CD5C5C',
    MAGENTA: '#FF00FF',
    LIGHT_YELLOW: '#FFFFE0',
    WHITE_SMOKE: '#F5F5F5',
}

const INDICATORS_COLORS = {
    BLUE: '#8fabdd',
    YELLOW: '#ffe699',
    RED: '#fe6869',
    GREY: '#a4a5a4',
    BLACK: '#404040',
}

const LARGE_RADIUS = '16px'
const MEDIUM_RADIUS = '8px'
const SMALL_RADIUS = '4px'

const mainTheme = createTheme({
    palette: {
        primary: {
            main: mainBlue,
        },
        secondary: {
            main: mainGreen,
        },
        black: {
            main: mainBlack,
        },
        action: {
            disabledBackground: 'lightgrey',
            disabled: 'white',
        },
    },
    typography: {
        fontFamily: ['Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        button: {
            fontSize: '1rem',
            textTransform: 'none',
        },
    },
    components: {
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '5px 10px',
                    margin: '0px',
                    ['&:last-child']: {
                        paddingBottom: '5px',
                    },
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    padding: '5px 10px 5px 20px',
                    minHeight: '40px',
                    height: '40px',
                    backgroundColor: 'white',
                },
                title: {
                    color: mainBlack,
                    fontWeight: 'bold',
                    fontSize: '1.3rem',
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: 'visible',
                    overflowY: 'hidden',
                },
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
                'aria-label': 'table',
            },
        },
        // MuiTableRow: {
        //     styleOverrides: {
        //         root: {
        //             // '&:last-child td, &:last-child th': { border: 0 },
        //         },
        //     },
        // },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    borderColor: 'black',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    cursor: 'pointer',
                    paddingRight: '32px',
                    textWrap: 'nowrap',
                },
                body: {
                    fontSize: '13px',
                    fontWeight: 'normal',
                    padding: '1 16',
                    backgroundColor: 'white',
                    textWrap: 'nowrap',
                },
            },
            variants: [
                {
                    props: { variant: 'bodyIcon' },
                    style: {
                        padding: '1',
                        width: '32px',
                        cursor: 'pointer',
                    },
                },
                {
                    props: { variant: 'headerIcon' },
                    style: {
                        borderColor: 'black',
                        cursor: 'pointer',
                        paddingRight: '16px',
                    },
                },
            ],
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    padding: '0 20',
                    ['&:last-child']: {
                        padding: '0 20',
                    },
                },
                selectLabel: {
                    fontSize: '0.9rem',
                },
                select: {
                    fontSize: '0.9rem',
                    width: '40px',
                    height: '20px',
                    paddingTop: '8px',
                },
                selectIcon: {
                    fontSize: '1.5rem',
                },
                displayedRows: {
                    fontSize: '0.9rem',
                },
                actions: {
                    '& button': {
                        '&:focus': {
                            backgroundColor: 'white',
                        },
                    },
                    '& svg': {
                        fontSize: '2rem',
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: '0px',
                    maxHeight: '250px',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    height: '30px',
                    fontSize: '0.9rem',
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {
                tooltip: {
                    // needed for SliderPanel
                    // todo add props to modify it
                    maxWidth: '100%',
                    fontSize: '0.8rem',
                    backgroundColor: '#292c4e',
                },
                arrow: {
                    color: '#292c4e',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: () => ({
                html: {
                    '*::-webkit-scrollbar': {
                        height: '8px',
                        width: '8px',
                    },
                    '*::-webkit-scrollbar-track': {
                        borderRadius: '20px',
                        backgroundColor: bgGrey,
                    },
                    '*::-webkit-scrollbar-thumb': {
                        borderRadius: '20px',
                        backgroundColor: lightGrey,

                        border: '1px solid transparent',
                        backgroundClip: 'content-box',
                        webkitBackgroundClip: 'content-box',
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#b7b7b7',

                        backgroundClip: 'border-box',
                        webkitBackgroundClip: 'border-box',
                    },
                },
                body: {
                    margin: 0,
                    padding: 0,
                    lineHeight: 'normal',
                },
                '& div#widgetMapAquasys .app, #widgetMapAquasys #map': {
                    height: '100% !important',
                    borderRadius: MEDIUM_RADIUS,
                    overflow: 'hidden',
                },
                '& div#widgetMapAquasys div.open': {
                    width: '27vw !important',
                },
            }),
        },
    },
}, frFR)

export {
    mainWhite,
    mainBlack,
    mainBlue,
    mainGreen,
    mainOrange,
    mainGrey,
    mainRed,
    secondaryBlue,
    secondaryGrey,
    strongGrey,
    mediumGrey,
    lightGrey,
    disabledColor,
    selectedColor,
    chartGrey,
    managerBlue,
    lightBlue,
    textBlue,
    badWarning,
    defaultPiezoColor,
    defaultHydroColor,
    defaultPluvioColor,
    defaultQualitoColor,
    defaultMonitoringColor,
    THRESHOLD_COLORS_CODES,
    INDICATORS_COLORS,
    mainTheme,
    LARGE_RADIUS,
    MEDIUM_RADIUS,
    SMALL_RADIUS,
}