import { ceil, floor, max, min } from 'lodash'
import moment from 'moment'
import { hasValue, round } from 'utils/NumberUtil'
import { getComponentWithId, removeNullKeys } from 'utils/StoreUtils'
import { H_24, H_48, HISTO, J30, J365, J7, J90 } from './ChartFollowContants'

const checkBarPatch = (minDateMoment, value) => {
    if (minDateMoment.valueOf() > value) {
        const component = getComponentWithId('.sieauChart')
        if (component) {
            const instance = component.getInstance()
            if (instance) {
                const options = instance.getOption()
                const xAxis = options.xAxis.map(axis => ({
                    ...axis,
                    min: minDateMoment.valueOf() + (minDateMoment.valueOf() - value),
                }))
                setTimeout(() => {
                    instance.setOption({ xAxis })
                }, 200)
            }
        }
    }
}

const getBarWidth = (nbElements) => {
    if (nbElements > 5000) {
        return 1
    }
    if (nbElements > 1000) {
        return 2
    }
    if (nbElements > 600) {
        return 3
    }
    if (nbElements > 100) {
        return 4
    }
    if (nbElements > 50) {
        return 6
    }
    if (nbElements > 20) {
        return 8
    }
    if (nbElements > 10) {
        return 15
    }
    return 25
}

const getChartDate = (minDate, maxDate, timeline = H_24) => {
    switch (timeline) {
        case H_24:
        case H_48:
            return {
                minDate: moment(minDate).add(1, 'h').startOf('hour').valueOf(),
                maxDate: moment(maxDate).add(1, 'h').startOf('hour').valueOf(),
            }
        case J7:
        case J30:
        case J90:
            return {
                minDate: moment(minDate).valueOf(),
                maxDate: moment(maxDate).endOf('day').valueOf(),
            }
        case J365:
            return {
                minDate: moment(minDate).endOf('month').valueOf(),
                maxDate: moment(maxDate).endOf('month').valueOf(),
            }
        case HISTO:
            return {
                minDate: moment(minDate).startOf('year').valueOf(),
                maxDate: moment().endOf('year').valueOf(),
            }
        default:
            return {
                minDate,
                maxDate,
            }
    }
}

const getSubstractTime = (newTime) => {
    switch (newTime) {
        case H_24:
            return moment().subtract(24, 'hours').valueOf()
        case H_48:
            return moment().subtract(48, 'hours').valueOf()
        case J7:
            return moment().subtract(7, 'days').valueOf()
        case J30:
            return moment().subtract(30, 'days').valueOf()
        case J365:
            return moment().subtract(1, 'years').valueOf()
        case J90:
        default:
            return moment().subtract(90, 'days').valueOf()
    }
}

// use barWidth on bar charts only
const getAxisIntervalFormatter = (maxDate, minDate, barWidth) => {
    const duration = moment.duration(maxDate.diff(minDate))
    if (duration.asMonths() >= 13) {
        const aYear = 3600 * 24 * 1000 * 365.5

        return {
            interval: (() => {
                if (duration.years() >= 30) {
                    return 4 * aYear
                }
                if (duration.years() >= 20) {
                    return 2 * aYear
                }
                return aYear
            })(),
            formatter: barWidth ? ((value) => {
                checkBarPatch(minDate, value)
                return moment(value).year()
            }) : (value => {
                if (moment(value).valueOf() > moment(value).month(11).date(10).valueOf()) { // arrondi à l'année proche
                    return `${moment(value).add(1, 'month').year()}`
                }
                return `${moment(value).year()}`
            }),
        }
    }
    if (duration.asMonths() >= 11) {
        return {
            interval: 3600 * 24 * 1000 * (duration.asDays() / duration.asMonths()),
            formatter: (value) => {
                if (barWidth) {
                    checkBarPatch(minDate, value)
                }
                return moment(value).format('DD/MM')
            },
        }
    }
    if (duration.asMonths() >= 2) {
        return {
            interval: 3600 * 24 * 1000 * (duration.asDays() / 13),
            formatter: (value) => {
                if (barWidth) {
                    checkBarPatch(minDate, value)
                }
                return moment(value).format('DD/MM')
            },
        }
    }
    if (duration.asDays() >= 20) {
        return {
            interval: 3600 * (duration.asHours() / (duration.days() / 2)) * 1000,
            formatter: (value) => moment(value).format('DD/MM'),
        }
    }
    if (duration.asDays() >= 5) {
        return {
            interval: 3600 * (duration.asHours() / duration.days()) * 1000,
            formatter: (value) => moment(value).format('DD/MM'),
        }
    }
    if (duration.asDays() > 1) {
        return {
            interval: 3600 * 4 * 1000,
            formatter: (value) => {
                const momentValue = moment(value)
                if (moment().add(1, 'h').startOf('hour').hour() === momentValue.hour()) {
                    return `${momentValue.format('DD/MM')}\n${momentValue.format('HH:mm')}`
                }
                return momentValue.format('HH:mm')
            },
        }
    }
    return {
        interval: 3600 * 2 * 1000,
        formatter: (value) => moment(value).format('HH:mm'),
    }
}

const setYOptionsPiezo = (optionOrNull, typeId, automaticScale, displayCote, lastLandmark, groundRefAlti, minAxisY = undefined, maxAxisY = undefined) => {
    const option = optionOrNull || {}

    return removeNullKeys({
        min: minAxisY ?? automaticScale.min,
        max: maxAxisY ?? automaticScale.max,
        splitNumber: (option.intervalYear || 5) - 1,
        interval: hasValue(automaticScale.max) && hasValue(automaticScale.min) ? (automaticScale.max - automaticScale.min) / (((option.intervalYear || 5) - 1) + 1) : null,
        axisLabel: { formatter: v => round(v, 2) },
    })
}

const setYOptions = (optionOrNull, automaticScale) => {
    const option = { ...(optionOrNull || {}), min: optionOrNull?.min || optionOrNull?.minYear, max: optionOrNull?.max || optionOrNull?.maxYear }
    const minValue = hasValue(option.min) ? option.min : automaticScale.min
    const maxValue = hasValue(option.max) ? option.max : automaticScale.max

    const usedMin = minValue < maxValue ? minValue : maxValue
    const usedMax = minValue < maxValue ? maxValue : minValue
    return removeNullKeys({
        min: usedMin,
        max: usedMax,
        splitNumber: (option.intervalYear || 5) - 1,
        interval: hasValue(usedMax) && hasValue(usedMin) ? (usedMax - usedMin) / (((option.interval || 5) - 1) + 1) : null,
        axisLabel: { formatter: v => round(v, 2) },
    })
}

const getMaxY = (value, pad, maxValueInData) => {
    if (value < maxValueInData) {
        return getMaxY(value + pad, pad, maxValueInData)
    }
    return value
}

const yAutomaticScaleValues = (values, minRoundPrecision = 1) => {
    if (values.length) {
        const minY = min(values)
        const maxY = max(values)
        const diff = maxY - minY
        if (diff < 1) {
            const baseObj = { min: floor(minY, minRoundPrecision), max: ceil(maxY, minRoundPrecision) }
            return { ...baseObj, splitNumber: 4, interval: round((baseObj.max - baseObj.min) / 5) }
        }
        if (diff < 5) {
            const baseObj = { min: floor(minY), max: ceil(maxY) }
            return { ...baseObj, splitNumber: 4, interval: round((baseObj.max - baseObj.min) / 5) }
        }
        if (diff < 10) {
            const tmpMin = floor(minY, -1) + (minY % 10 > 5 ? 5 : 0)
            const baseObj = { min: tmpMin, max: getMaxY(tmpMin + 10, 10, maxY) }
            return { ...baseObj, splitNumber: 4, interval: round((baseObj.max - baseObj.min) / 5) }
        }
        const tmpMin = floor(minY, -1)
        const pad = [10, 20, 40, 50, 80, 100, 120, 150, 200, 250, 400, 500, 800, 1000, 1200, 1400, 1500, 2000, 2500, 5000, 10000, 20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000, 10000000, 20000000, 50000000, 100000000].find(maxDiff => diff < maxDiff)
        const baseObj = { min: tmpMin, max: getMaxY(tmpMin + pad, pad, maxY) }
        return { ...baseObj, splitNumber: 4, interval: round((baseObj.max - baseObj.min) / 5) }
    }
    return {}
}

const fullScreenIcon = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4gwaEBYYft7EIgAAAh9JREFUSMfdlj9rFFEUxX8jmwjZGJLGxICxMBL9ABZ+hzm7rtHGSjsLCxtt/VMKi4q9ICiIbNzITRd7C7+AqE0UjRZilNgYdWzuk7ezMy9r64WBx9x7z73v3DfnTSbpJHAWKBi0DPhgZpdImKQ7wHxN/oNMUkHazpnZ/Rrw88C9VPKeindbwFd/XgKrify+x3yJngFrVCR9NrPFqMtUg1tmdiyKfQPMDPAUUbQNTDqXn4AFYMfM2GUGAGPAW2DWuQ9YAxTdAHoeMOsJY6kdRODvIvCeYw3NoGlmZ0YtUgLfH8Ado1k5ZEl4wEqpyHhcxNd7q8DdV8QFLgDPgK6Zkec5Zna6VGQtnoWv16rA3dcF1h17+LSEtaTHkgpJtysouuW+R3X5SWu1WiF4EqDdbv/15XkefBMjA/5/Fs1hukxRRN/0yBTFQRHH/cSQ77qvlxpyQ9JFYBloA9/CUZO06u8K4GhFT0vuW5b0xMxORcd0StJTYCXWomvA9Qrwj8ChWJe8w3FgI5KIfijiWFfLX3Lm4P0S+JDo+foHcNBjCqDjO6FOTa8AJ4COJ2x65z/rFNW7bbiczIWdAM+Bm+UC312kAvgC8Osf5HojKhKwBi6coICvzWypJIC14O7bAeYlvQKOxGpadaPNSdr29SZw3K/RKpuR9MI7B/g9yp085R00gUWglWCoAxyO4veVAzJJeeK35b2ZXd5lBl3gQE3+wz+wZvJoBRE1rQAAAABJRU5ErkJggg=='

const exportPictureIcon = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAWRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIj4KICAgICAgICAgPHhtcDpDcmVhdG9yVG9vbD5BZG9iZSBJbWFnZVJlYWR5PC94bXA6Q3JlYXRvclRvb2w+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpqMDBXAAABiElEQVRIDbVV7XGCQBCFgwIowXSQdKAdmAoyVhAECgj/GeZSgXYQU4GWYAexg5D/fOQt4zInB4qH7My5H9y+d3e7d1rWxGITfhiGy6qqNjC9B/NJ2/d9TwjxMwF4vVbhuu7zVODEIGqaCX/ckdgS+X8YbxgzDE2MCVC3RZIkB0JEHWVfHY2OCB13YHAikFJmUJ9kt8WIoA1yzTcisG17jmOh7lOF6qDJmBrsgyBYgywry/IdyDMNHYEhBDvM+8agm66KB2eDelggUeMX9s0jwurWaZpukUXjbrlFsEWHnAiViKBqm/yhcpUAoDEDUSvCf2V/qO4loF7n1TMY/CPshpTj13QvgeM4nUCoxweRt0BjFJrINekkIAD1praz8H2FWHaOr4i0KIqFEmtSOgn6Vs9ZdHSoxwuNc4fVzwV2QcQXYkdRNMfEvRI9IelJ8e8ycfm+kLDkpK4dxPzRRGOxtAs+Pv0PBxN2JsCcQ+0Mu3lZtacC7/ovtsnzR2uR5/kRKM2WRiO2AP4B0TKNi/pEGVoAAAAASUVORK5CYII='

const exportExcelIcon = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAWRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIj4KICAgICAgICAgPHhtcDpDcmVhdG9yVG9vbD5BZG9iZSBJbWFnZVJlYWR5PC94bXA6Q3JlYXRvclRvb2w+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpqMDBXAAABBUlEQVRIDe2U0Q2CMBCGbeMAjuAIjAAjMIFhg4bwjixAHcENHEFHcBQmAP8jYspBKQV9MOESaO96939peyB2DkvTVDdNE0ylaa0j2/rettDFSVwIEXa+7yh9C3zzN4DzxP7/iIS5R/S8gn8wY5if8BxZjLsFD9R1fYFVve8AwYeU8o5kDuH13M9ZICFxivV2QAGlVLAQQuVkSVmW13aG1wBACysgPXHSGgUshAzEJwGekFFxJ2AmxCpO9c4PDd3wRHdFyG27goreVuEvG5sX2i2Yo/UOzCSas4uvCEpwnsf92QADcoN4PEecwzZ/9AQ+d5BlWYizpf/Qt6xAh52dbbqW9nPACyJpV919J488AAAAAElFTkSuQmCC'

const infoIcon = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAACxAAAAsQHGLUmNAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAZFJREFUSIm11b9OVFEQBvDfWgO1YgLRpcTC5Tm0orK30xLXUjc8BNnY6gNgxVKYSOy0gtYYTbwI76BLMXP1utn7Z3H9kkluzpn5vpm5Z87hP6PXwWcTD7GN9VwrcIZDfL2u+A6OMU0r8DGtqKxPMFiEuId9/MIP7OHOHL+7eIaL9B3p0JEbeJOZHWClQ0IrGGfM6zaR/XTc60A8i2HGjuocdkSpB9cgLzHGTzX/5Fj0vKktH3DSsL8q/slkdmNTt9ac4H2Lz1B0YqO6+DQF5p2WRdFPrifEqSGGqMCXluDHaU34LFq9XRW4nQJteJTWhu9y6kuBqW7XRlf0kvO3QOHPPbMMrOO8KnCGW2L8/xVbuInTqsChKGl3CQK7yfV2dmMihmS1IfhdWh3WcImjeZsDMebjBoK2Y/oqOe7XOYxEecMGkjo8z9gXTU49ceVORSVN7SqxJjLvdF2XIiNR6oWopj/Hr59ZX6bvyy7kVQzEjy+fxnPxXH7K73L9SEPPuyhu4AHu+fvRPxVH8dsiWS8dV3ZOYqGFUFn5AAAAAElFTkSuQmCC'

const histogramIcon = 'path://m 10 100 h 80 v -4 h -80 v 4 m 0 -20 v -70 h 20 v 70 h -20 m 30 0 v -40 h 20 v 40 h -20 m 30 0 v -55 h 20 v 55 h -20 z'
const lineIcon = 'path://m 10 100 h 80 v -4 h -80 v 4 m 0 -60 l 9 3 l 16 -33 l 18 70 l 10 -40 l 10 20 l 17 -10 v 2 l -17 10 l -10 -20 l -10 40 l -18 -70 l -16 33 l -9 -3 v -2 z'
const stackIcon = 'path://m 10 30 l 50 -20 l 30 20 l -50 20 l -30 -20 m 0 13 l 30 20 l 50 -20 l -12 -8 m -57 2 l -11 6 m 0 13 l 30 20 l 50 -20 l -12 -8 m -57 2 l -11 6 m 0 13 l 30 20 l 50 -20 l -12 -8 m -57 2 l -11 6 z'
const logIcon = 'path://m 0 25 v 45 h 25 v -5 h -20 v -40 h -5 m 35 0 v 45 h 25 v -45 h -25 m 5 5 h 15 v 35 h -15 v -35 m 30 -5 v 45 h 30 v -25 h -15 v 5 h 10 v 15 h -20 v -35 h 25 v -5 h -30 z '
// const thresholdIcon = 'path://m 0 66 h 100 v 2 h -100 v -2 m 10 -20 l 9 3 l 16 -33 l 18 70 l 10 -40 l 10 6 l 17 -15 v 2 l -17 15 l -10 -6 l -10 40 l -18 -70 l -16 33 l -9 -3 v -2 z '
const thresholdIcon = 'path://m 0 40 h 100 v 2 h -100 v -2 m 10 20 l 9 3 l 16 -53 l 18 70 l 10 -20 l 10 6 l 17 -15 v 2 l -17 15 l -10 -6 l -10 20 l -18 -70 l -16 53 l -9 -3 v -2 z '
const chartSymbol = 'path://M 21.20,15.00 C 21.20,15.00 25.20,9.70 25.20,9.70M 13.80,7.70 C 13.80,7.70 19.20,14.30 19.20,14.30M 7.80,15.70 C 7.80,15.70 11.20,7.70 11.20,7.70M 2.50,17.70 C 2.50,17.70 4.50,17.70 4.50,17.70M 27.20,10.30 C 28.30,10.30 29.20,9.40 29.20,8.30 29.20,7.20 28.30,6.30 27.20,6.30 26.10,6.30 25.20,7.20 25.20,8.30 25.20,9.40 26.10,10.30 27.20,10.30 Z M 19.20,18.30 C 20.30,18.30 21.20,17.40 21.20,16.30 21.20,15.20 20.30,14.30 19.20,14.30 18.10,14.30 17.20,15.20 17.20,16.30 17.20,17.40 18.10,18.30 19.20,18.30 Z M 12.50,8.30 C 13.60,8.30 14.50,7.40 14.50,6.30 14.50,5.20 13.60,4.30 12.50,4.30 11.40,4.30 10.50,5.20 10.50,6.30 10.50,7.40 11.40,8.30 12.50,8.30 Z M 6.50,19.00 C 7.60,19.00 8.50,18.10 8.50,17.00 8.50,15.90 7.60,15.00 6.50,15.00 5.40,15.00 4.50,15.90 4.50,17.00 4.50,18.10 5.40,19.00 6.50,19.00 Z M 1.80,25.00 C 1.80,25.00 29.20,25.00 29.20,25.00M 1.80,1.00 C 1.80,1.00 1.80,25.00 1.80,25.00'
const chartLine = 'path://M 20 15 L 26 9 M 12 6 L 20 15 M 7 17 L 12 6 M 2.5 17.7 L 7 17 Z M 1.8 25 C 1.8 25 29.2 25 29.2 25 M 1.8 1 C 1.8 1 1.8 25 1.8 25'
const legendSymbol = 'path://m 15 18 a 7 7 0 1 0 1 0 l -1 0 m 20 0 l 55 0 l 0 14 l -55 0 l 0 -14 m -20 25 a 7 7 0 1 0 1 0 l -1 0 m 20 0 l 55 0 l 0 14 l -55 0 l 0 -14 m -20 25 a 7 7 0 1 0 1 0 l -1 0 m 20 0 l 55 0 l 0 14 l -55 0 l 0 -14 z '

export {
    chartLine, chartSymbol, exportPictureIcon, fullScreenIcon, getAxisIntervalFormatter, histogramIcon, legendSymbol, lineIcon, logIcon,
    setYOptions, stackIcon, thresholdIcon, yAutomaticScaleValues, getBarWidth, setYOptionsPiezo, exportExcelIcon, infoIcon, getChartDate, getSubstractTime,
}
